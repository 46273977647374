import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleBackgroundImage from '../components/PreviewCompatibleBackgroundImage'
import Slider from "react-slick"

export default class Gallery extends React.Component {
  render() {
    const { gridItems } = this.props
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        {gridItems.map(item => (
          <div key={item.text}>
            <PreviewCompatibleBackgroundImage key={item.image} imageInfo={item.image} imageText={item.text} />
          </div>
        ))}
      </Slider>
    );
  }
}

Gallery.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}
