import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import PreviewCompatibleBackgroundImage from '../components/PreviewCompatibleBackgroundImage'
import { Link } from 'gatsby'

export const HomePageTemplate = ({
  blockOne,
  blockTwo,
  blockThree,
  blockFour,
  blockFive,
  gallery,
}) => (
  <div className="homepage">

    <section className="first">
      <div className="blockOne">
        <PreviewCompatibleBackgroundImage imageInfo={blockOne.image} />
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1>{blockOne.title}</h1>
              <p>{blockOne.description}</p>
              <Link to="/contact" className="btn-secondary" title="Logo">Contactez-nous</Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="blockTwo">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3>{blockTwo.title}</h3>
              <p>{blockTwo.description}</p>
            </div>
          </div>
          <PreviewCompatibleBackgroundImage imageInfo={blockTwo.image} />
        </div>
      </div>
    </section>

    <section>
      <div className="blockThree">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h3>{blockThree.title}</h3>
              <p>{blockThree.description}</p>
            </div>
            <div className="col-md-7 offset-md-2">
              <PreviewCompatibleBackgroundImage imageInfo={blockThree.image} />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="blockFour">
              <PreviewCompatibleBackgroundImage imageInfo={blockFour.image} />
              <h3>{blockFour.title}</h3>
              <p>{blockFour.description}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="blockFive">
              <PreviewCompatibleBackgroundImage imageInfo={blockFive.image} />
              <h3>{blockFive.title}</h3>
              <p>{blockFive.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section>
      <div className="container">
        <Gallery gridItems={gallery.blurbs} />
      </div>
    </section>

  </div>
)

HomePageTemplate.propTypes = {
  blockOne: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  blockTwo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  blockThree: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  blockFour: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  blockFive: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <HomePageTemplate
        blockOne={frontmatter.blockOne}
        blockTwo={frontmatter.blockTwo}
        blockThree={frontmatter.blockThree}
        blockFour={frontmatter.blockFour}
        blockFive={frontmatter.blockFive}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        blockOne {
          title
          description
          image
        }
        blockTwo {
          title
          description
          image
        }
        blockThree {
          title
          description
          image
        }
        blockFour {
          title
          description
          image
        }
        blockFive {
          title
          description
          image
        }
        gallery {
          blurbs {
            image
            text
          }
        }
      }
    }
  }
`
