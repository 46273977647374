import React from 'react'
import PropTypes from 'prop-types'

const PreviewCompatibleBackgroundImage = ({ imageInfo, imageText }) => {
  return (
    <div
      className="bgImg"
      style={{backgroundImage: `url(${imageInfo})`,}}
    >
      <div className="description">{imageText}</div>
    </div>
  )
}

PreviewCompatibleBackgroundImage.propTypes = {
  imageInfo: PropTypes.string.isRequired,
}

export default PreviewCompatibleBackgroundImage
